// Skills Icons
import htmlIcon from "./images/html.svg"
import cssIcon from "./images/css.svg"
import reactIcon from "./images/react.svg"
import jsIcon from "./images/javascript.svg"
import designIcon from "./images/design.svg"
import codeIcon from "./images/code.svg"

// Social Icon
import githubIcon from "./images/github.svg"
// import codepenIcon from "./images/codepen.svg"
import codepenIcon from "./images/Petronas_Drop.svg"
import dribbbleIcon from "./images/dribbble.svg"
import instagramIcon from "./images/instagram.svg"

export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  name: "chriswylim",
  headerTagline: [
    //Line 1 For Header
    "Building digital",
    //Line 2 For Header
    "products, brands,",
    //Line 3 For Header
    "and experience",
  ],

  //   Header Paragraph
  headerParagraph:
    "AWS Architect by passion; DevOps Executive by day; M&A Engineer by training",

  //Contact Email
  contactEmail: "chris@hiddenplateau.com",

  // End Header Details -----------------------


  // Work Section ------------------------
  projects: [
    {
      title: "Project One", //Project Title - Add Your Project Title Here
      para:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1487837647815-bbc1f30cd0d2?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8Njl8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "http://linkedin.com/in/chriswylim",
    },
    {
      title: "Project Two", //Project Title - Add Your Project Title Here
      para:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1605153864431-a2795a1b2f95?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXwyNTY3ODl8fGVufDB8fHw%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "http://linkedin.com/in/chriswylim",
    },
    {
      title: "Project Three", //Project Title - Add Your Project Title Here
      para:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1504083898675-c896ecdae86e?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8MjJ8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "http://linkedin.com/in/chriswylim",
    },
    {
      title: "Project Four", //Project Title - Add Your Project Title Here
      para:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1492171983775-a51717616c0d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NjB8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "http://linkedin.com/in/chriswylim",
    },
    {
      title: "Project Five", //Project Title - Add Your Project Title Here
      para:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1534239143101-1b1c627395c5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NzZ8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "http://linkedin.com/in/chriswylim",
    },
    {
      title: "Project Six", //Project Title - Add Your Project Title Here
      para:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1517479149777-5f3b1511d5ad?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTAyfHxwYXN0ZWx8ZW58MHx8MHw%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "http://linkedin.com/in/chriswylim",
    },

    /*
    If You Want To Add More Project just Copy and Paste This At The End
    ,{
        title: 'Project Five',
        para: 'Something Amazing',
        imageSrc: "",
        url: ''
    }
    */
  ],

  // End Work Section -----------------------


  // About Secton --------------
  aboutParaOne:
    "Currently employed as a Senior Executive in PETRONAS Digital, Malaysia. AWS Certified Solutions Architect - Professional since 2021. I am the SME for subjects like Infrastructure as Code, Configuration Management, Cloud Architecting, Containerization, CI/CD, and so on ...",
  aboutParaTwo:
    "Enjoy fixing things, adverse risk-taker and constant adaptator from environment changes. Since 2016, I've dipped in fields like Mech Eng, E&E Eng, Civil Eng, IT Architecting and Development-Operations (DevOps). (IT realm is mysterious and fun!)",
  aboutParaThree:
    "Actively engaging with tools like, HashiCorp Terraform, HashiCorp Vault, Microsoft Azure Cloud Computing Services, Amazon Web Services (AWS), Red Hat Ansible, ELK Stack, Jenkins, Atlassian Bamboo, Atlassian Confluence, Azure DevOps, etc.",
  aboutImage:
    "https://chriswylim.s3.ap-southeast-1.amazonaws.com/helloimchris.jpg",

  //   End About Section ---------------------


  // Skills Section ---------------

  //   Import Icons from the top and link it here

  skills: [
    {
      img: htmlIcon,
      para:
        "Infrastructure as Code - HashiCorp Terraform, AWS CloudFormation, Azure Resource Manager",
    },
    {
      img: cssIcon,
      para:
        "Configuration Management - Ansible, Chef, Puppet",
    },
    {
      img: jsIcon,
      para:
        "CI/CD - Jenkins, Azure DevOps, BitBucket",
    },
    {
      img: reactIcon,
      para:
        "Cloud Computing Services - AWS and Azure",
    },
    {
      img: designIcon,
      para:
        "Coding Languages - Python, JavaScript, Shell, Go",
    },
    {
      img: codeIcon,
      para:
        "Observability and ChatOps - ELK, Project Dashboards, Teams/Slack ChatOps Integration",
    },
  ],

  // End Skills Section --------------------------


  //   Promotion Section --------------------------

  promotionHeading: "How Did I Make This?",
  promotionPara:
    "This is a typical static website hosted on Amazon S3, delivered by CloudFront (the choice of CDN provider). The website is also HTTPS encrypted (L7-server side encryption) using ACM (as Certificate Manager) and Route53 (for Domain Management). The UI is written in React-Native stored in a private GitHub repository. All the contents are stored in a private S3 bucket, accesible via an OAI (Origin Access Identity) - AWS CloudFront feature.",
  // End Promotion Section -----------------


  //   Contact Section --------------

  contactSubHeading: "Let's create your next experience together",
  social: [
    // Add Or Remove The Link Accordingly
    { img: githubIcon, url: "https://github.com/chriswylim" },
    {
      img: codepenIcon,
      url: "https://www.PETRONAS.com/",
    },
    {
      img: dribbbleIcon,
      url: "https://dribbble.com/",
    },
    {
      img: instagramIcon,
      url: "https://www.instagram.com/chriswylim",
    },
  ],

  // End Contact Section ---------------
}

// Thanks for using this template, I would love to hear from you contact me at hello@chetanverma.com
