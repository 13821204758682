import React from "react"
import Fade from "react-reveal/Fade"
import data from "../yourdata"

const Promotion = () => {
  return (
    <div className="section">
      <div className="container">
        <div className="promotion-container">
          <Fade bottom cascade>
            <h1>{data.promotionHeading}</h1>
          </Fade>
          <p>{data.promotionPara}</p>
          <a href="https://medium.com/tensult/creating-aws-cloudfront-distribution-with-s3-origin-ee47b8122727">
            Click Here to Learn How
          </a>
        </div>
      </div>
    </div>
  )
}

export default Promotion
